export default {
  signIn: {
    url: '/auth/token/login',
    version: '/v1',
    method: 'post'
  },
  logOut: {
    url: '/auth/token/logout',
    version: '/v1',
    method: 'post'
  },
  getInfo: {
    url: '/profile',
    version: '/v1',
  }
}
