export const setCookie = ({ key, value }) => {
  if (typeof document === 'undefined') return
  document.cookie = key + '=' + JSON.stringify(value) + '; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT'
}

export const getCookie = name => {
  if (typeof document === 'undefined') return
  const value = `; ${ document.cookie }`;
  const parts = value.split(`; ${ name }=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
