import axios from 'axios'
import { getCookie } from "./cookie"

//https://internet.find-best.ru

// const server = process.env.NODE_ENV === 'development' ? 'https://partner.fitness.find-best.ru' : process.env.HOST
const server = process.env.NODE_ENV === 'development' ? 'https://partner.fitness.find-best.ru/' : process.env.HOST
// const server = process.env.NODE_ENV === 'development' ? 'https://partner.fitness.gde-luchshe.ru/' : process.env.HOST

console.log(process.env.HOST, process.env.PORT, 'process.env')

export const fetcher = async ({
                                method = 'get',
                                url,
                                isGlobalApi,
                                api,
                                params,
                                headers = {},
                                version = '/v1',
                                isFormData,
                                withoutToken,
                                isData = true,
                                errorMessage = '',
                                responseType
                              }) => {
  url = (api || '/api') + version + url
  const firstParam = url.indexOf('?') < 0
  console.log(axios.defaults, 'axios.default')
  if (typeof window !== 'undefined' && !axios.defaults.headers.Authorization && getCookie('token')) {
    axios.defaults.headers.Authorization = 'Token ' + getCookie('token').replace(/\"/g, '')
  }
  if (withoutToken) {
    delete axios.defaults.headers.Authorization
  }

  if (method === 'get' && !!params && Object.keys(params).length > 0) {
    let pramsToString = Object.keys(params).map((key, index) => {
      console.log(params[ key ], key)
      let param = (!index && firstParam ? '?' : '&') + key + "=" + params[ key ]
      if (typeof params[ key ] === 'object') {
        if (Object.keys(params[ key ]).length) return param
      }
      else {
        if (!!params[ key ] || params[ key ] === 0) return param
        else return ''
      }
    }).join('')
    url += pramsToString
  }

  headers[ 'API-DOS-VERSION' ] = headers[ 'API-DOS-VERSION' ] || '1.1'

  if (process.title.indexOf('node') >= 0) {
    url = server + url
  }

  if (isFormData && typeof FormData !== 'undefined') {
    const formData = new FormData()
    Object.keys(params).forEach((key) => {
      formData.append(key, params[ key ])
    })

    params = formData
  }

  //return await axios[ method ](url, params)

  try {
    let res = await axios({
      method,
      url,
      data: params,
      headers,
      responseType
    })
    console.log(res.status)
    if (String(res.status).indexOf('2') !== 0 || (isData && (res.data.data ? res.data.data : res.data) === null)) {
      throw errorMessage + ' ' + new Error()
    }
    else {
      console.log(res.status)
      res = res.data.data ? res.data.data : res.data
      if (isData && res.code && res.code === 500) throw errorMessage + ' ' + new Error()
      else return res
    }
  } catch (err) {
    throw errorMessage + ' ' + err
  }
}

export const createApiUrl = ({ url, api, version = '/v1', paramToQuery, params }) => {
  let fullUrl = (api || '/api') + version + url
  if (process.title.indexOf('node') >= 0) {
    fullUrl = server + fullUrl
  }
  if (paramToQuery) {
    let pramsToString = Object.keys(params).map((key, index) => {
      const firstParam = url.indexOf('?') < 0
      let param = (!index && firstParam ? '?' : '&') + key + "=" + params[ key ]
      if (typeof params[ key ] === 'object' && params[ key ] !== null) {
        if (Object.keys(params[ key ]).length) return param
      }
      else {
        if (!!params[ key ] || params[ key ] === 0) return param
        else return ''
      }
    }).join('')
    fullUrl += pramsToString
  }
  return fullUrl
}
