import { createParamsApi } from "../../../api"
import { fetcher } from "@/utils/fetcher"
import { getCookie, setCookie } from "@/utils/cookie"
import routes from "@/routes/index"
import { resetModalModel } from "@/store/modal/actions"

export const SET_AUTH_STATUS = 'SET_AUTH_STATUS'
export const SET_AUTH_START_AUTH = 'SET_AUTH_START_AUTH'
export const SET_SIGN_IN_INFO = 'SET_SIGN_IN_INFO'
export const SET_SIGN_IN_ERROR = 'SET_SIGN_IN_ERROR'
export const CHANGE_SIGN_IN_FETCHING = 'CHANGE_SIGN_IN_FETCHING'

export const signIn = ({ user, pass, router }) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_SIGN_IN_FETCHING, status: true })
  dispatch({ type: SET_AUTH_START_AUTH, status: true })
  dispatch({ type: SET_SIGN_IN_ERROR, status: false })
  try {
    if (user.length <= 0 || pass <= 0) throw Error()
    let api = createSignInApi({ user, pass })
    let { auth_token } = await fetcher(api)
    console.log(auth_token)
    setCookie({ key: 'token', value: auth_token })
    setAuthStatus({ status: true })(dispatch, getState)
    router.push('/')
    await getUserInfo()(dispatch, getState)
    dispatch({ type: CHANGE_SIGN_IN_FETCHING, status: false })
    dispatch({ type: SET_AUTH_START_AUTH, status: false })
  } catch (err) {
    dispatch({ type: CHANGE_SIGN_IN_FETCHING, status: false })
    dispatch({ type: SET_SIGN_IN_ERROR, status: true })
  }
}

export const getManualSearchStatus = () => (dispatch, getState) => {
  const { club } = getState().user.auth.signInInfo
  const { lk_settings } = club
  return lk_settings.manual_search
}

export const setAuthStatus = ({ status }) => (dispatch, getState) => {
  dispatch({ type: SET_AUTH_STATUS, status })
}

export const createSignInApi = ({ user, pass }) => {
  let api = createParamsApi({
    section: 'auth',
    method: 'signIn',
    errorMessage: 'signIn'
  })
  api.params = {
    username: user,
    password: pass
  }
  api.withoutToken = true
  return api
}

export const getUserInfo = () => async (dispatch, getState) => {
  /* istanbul ignore next */
  try {
    let res = await fetcher(createParamsApi({
      method: 'getInfo',
      errorMessage: 'getUserInfo',
      section: 'auth',
    }))
    const { user } = res
    dispatch({ type: SET_SIGN_IN_INFO, info: user })
    setAuthStatus({ status: true })(dispatch)
  } catch (err) {
    console.log(err)
  }
}

export const logOut = (router) => (dispatch, getState) => {
  let res = fetcher(createParamsApi({
    method: 'logOut',
    errorMessage: 'logOut',
    section: 'auth',
  }))
  setAuthStatus({ status: false })(dispatch, getState)
  router.push('/auth/')
  setCookie({ key: 'token', value: '' })
  resetModalModel()(dispatch, getState)
}

export const checkIsAuth = ({ router, res }) => (dispatch, getState) => {
  const { isAuth } = getState().user.auth
  if (!isAuth) {
    if (res) {
      res.writeHead(301, {
        Location: '/auth',
        'Content-Type': 'text/html; charset=utf-8',
      })
      res.end()
    }
    else {
      router.push('/auth/')
    }
  }
  return isAuth
}

export const checkTokenCookie = ({ router }) => (dispatch, getState) => {
  let token = getCookie('token')

  token = token ? JSON.parse(token) : token

  if (!token && routes.checkPrivateRoute(router.pathname)) {
    console.log('unAuth')
    setAuthStatus({ status: false })(dispatch, getState)
    checkIsAuth({ router })(dispatch, getState)
  }
}

export const redirectAuthUser = ({ router, res, pathname }) => (dispatch, getState) => {
  const { isAuth } = getState().user.auth
  if (isAuth && pathname.indexOf('/auth') === 0) {
    if (res) {
      res.writeHead(301, {
        Location: '/',
        'Content-Type': 'text/html; charset=utf-8',
      })
      res.end()
    }
    else {
      router.push('/')
    }
  }
}
