import auth from './auth'
import order from './order'
import global from './global'
import stats from './stats'

const api = {
  auth,
  order,
  global,
  stats
}

export const createParamsApi = ({ method, section, errorMessage, params = {}, apiUrlParams, frontEndToBackEnd }) => {

  let createdApi = api[ section ][ method ]
  if (typeof createdApi === 'function') createdApi = createdApi(apiUrlParams)
  createdApi = { ...createdApi, errorMessage }
  createdApi.params = createdApi.params && Object.keys(createdApi.params).length > 0 ? createdApi.params : {}
  Object.keys(params).forEach(key => {
    if (frontEndToBackEnd) {
      if (frontEndToBackEnd[ key ] && (params[ key ] || +params[ key ] === 0)) createdApi.params[ frontEndToBackEnd[ key ] ] = params[ key ]
    }
    else {
      if (params[ key ] || params[ key ] === 0) createdApi.params[ key ] = params[ key ]
    }
  })
  return createdApi
}
