export default {
  getOrders: {
    url: '/orders?',
    version: '/v1',
  },
  closeOrder: ({ id }) => ({
    url: `/orders/${ id }/visit`,
    version: '/v1',
    method: 'post'
  }),
  refuseOrder: ({ id }) => ({
    url: `/orders/${ id }/refuse`,
    version: '/v1',
    method: 'post'
  }),
  exitCustomer: ({ id }) => ({
    url: `/orders/${ id }/finish_training`,
    version: '/v1',
    method: 'post'
  }),
  getOrdersCard: {
    url: '/club_card_orders',
    version: '/v1',
  },
  updateOrder: ({ id, comment, status }) => ({
    url: `/club_card_orders/${ id }/update`,
    version: '/v1',
    isFormData: true,
    method: 'post',
    params: {
      comment,
      status
    }
  })
}
