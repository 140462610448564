export default {
  leftSideBar: [
    {
      href: '/',
      as: '/',
      title: 'Заказы',
      icon: 'SearchSignIn'
    },
    {
      href: '/order-card',
      as: '/order-card',
      title: 'Клубные карты',
      icon: 'Card'
    },
    {
      href: '/statistics',
      as: '/statistics',
      title: 'Статистика',
      icon: 'Statistics'
    },
    {
      isInstruction: true,
      title: 'Инструкция',
      icon: 'Blog',
      isNativeLink: true
    },
    {
      href: '/support',
      as: '/support',
      title: 'Поддержка',
      icon: 'Support'
    }
  ],
  mobileSideBar: [
    {
      href: '/',
      as: '/',
      title: 'Заказы',
      icon: 'SearchSignIn'
    },
    {
      href: '/order-card',
      as: '/order-card',
      title: 'Клубные карты',
      icon: 'Card'
    },
  ],
  otherOurService: [
    // {
    //   title: 'Интернет для бизнеса',
    //   href: 'https://telecom.gde-luchshe.ru/',
    //   isNativeLink: true,
    //   bg: 'linear-gradient(90.52deg, #1557FF 0%, #47A9E1 99.55%)'
    // },
    {
      title: 'Домашний интернет',
      href: 'https://internet.gde-luchshe.ru/',
      isNativeLink: true,
      bg: '#E94B0D'
    },
    {
      title: 'Интернет в офис',
      href: 'https://telecom.gde-luchshe.ru/',
      isNativeLink: true,
      bg: '#E94B0D'
    },
    // {
    //   title: 'Онлайн-курсы',
    //   href: 'https://telecom.gde-luchshe.ru',
    //   isNativeLink: true,
    //   bg: '#EB5757'
    // },
  ],
  additionalLinks: [
    {
      title: 'О проекте',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Партнерская программа',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Стать партнером',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Вакансии',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
    {
      title: 'Контакты',
      href: '/',
      as: '/',
      icon: 'SearchSignIn'
    },
  ],
  terms: {
    privacy: {
      href: '/privacy_policy/',
      as: '/privacy_policy/',
    },
    userAgreement: {
      href: '/user_agreement/',
      as: '/user_agreement/',
    }
  },
  profile: {
    account: {
      href: '/profile/account',
      as: '/profile/account',
      title: 'Личный кабинет'
    },
    training: {
      title: 'Тренировки',
      href: '/profile/training',
      as: '/profile/training',
    }
  },
  auth: {
    recovery: {
      href: '/auth/recovery',
      as: '/auth/recovery/',
    },
    singIn: {
      href: '/auth',
      as: '/auth/',
    }
  },
  checkPrivateRoute(pathname) {
    return pathname.indexOf('/auth') < 0
  }
}
